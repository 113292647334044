$primary: #191C2C;
// $secondary:     $gray-600;
// $success:       $green;
// $info:          $cyan;
// $warning:       $yellow;
// $danger:        $red;
// $light:         $gray-100;
// $dark:          $gray-800;

@import "node_modules/bootstrap/scss/bootstrap";
//@import /datetimepicker
