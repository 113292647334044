.dot {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  display: inline-block;

  &--green {
    background-color: $item-green; }

  &--red {
    background-color: #ff0034; } }
