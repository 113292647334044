.toast {
  position: fixed;
  bottom: 5px;
  right: 10px;
  width: 400px;
  min-height: 70px;
  z-index: 10000;  // this value will put the toast on top of modal backdrops

  background: #FDFFFE;
  border: none;
  border-radius: 5px;
  color: #161D32;
  padding: 10px;

  .toast-header {
    border-bottom: 0;
    padding: 0 28px;
    strong {
      font-size: 16px;
      color: #161D32 !important; } }

  .toast-body {
    font-size: 1.4em;
    padding: 5px 33px 0 29px; }

  i {
    position: absolute;
    left: 12px;
    top: 13px;
    font-size: 18px; }

  .close {
    color: #000;
    position: absolute;
    right: 15px;
    font-size: 4em;
    top: calc(55% - 20px);
    outline: none; } }
