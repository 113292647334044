.page-wrapper {
  position: fixed;
  overflow: auto;
  height: 100%;
  width: 100%;
  padding-top: 10rem;
  transition: background-image 0.2s linear;

  &--car-window {
    background: {
      cover: #191C2C;
      image: url('~assets/images/backgrounds/auto-automobile-automotive-blur.png');
      size: cover; } }

  &--airport {
    background: {
      cover: #191C2C;
      image: url('~assets/images/backgrounds/view-of-city-at-airport-light.png');
      size: cover; } }

  &--nature-road {
    background: {
      cover: #191C2C;
      image: url('~assets/images/backgrounds/road-landscape-nature-forest.png');
      size: cover; } }

  &--road {
    background: {
      cover: #191C2C;
      image: url('~assets/images/backgrounds/road.png');
      size: cover; } }

  &__inner {
    margin: 2rem 7rem; }

  &__header {
    justify-content: space-between;
    align-items: center;

    h3 {
      font-weight: 700;
      color: #FFFFFF;
      font-size: 2rem; }

    &--title {
      font-size: 2.2rem; } }

  &__content {
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.5);
    background: $card-bg;
    border: 1px solid #353f6d;
    border-top: 0;
    margin-top: 3rem; }

  &__gradient {
    height: .25rem;
    background: transparent linear-gradient(90deg, #54EAAC 0%, #B4E10D 100%) 0% 0% no-repeat padding-box;

    &--red-blue {
      background: transparent linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,128,255,1) 100%) 0% 0% no-repeat padding-box; } } }

.btn {
  font-weight: 500; }

.padded-container {
  padding: 3rem 3rem 4rem 3rem;
  background: $card-bg; }
