// Pulse Animation
@-webkit-keyframes pulse-animation {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1.0); } }

@keyframes pulse-animation {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1.0); } }

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

.rotating {
  -webkit-animation: rotating 2s linear infinite; }
