.circle-icon {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    pointer-events: none; }

  &.circle-icon--blue {
    background-color: $item-lighter-blue; }

  &.circle-icon--green {
    background-color: $item-green; }

  &.circle-icon--red {
    background-color: $item-red; } }
