.small-tms-loader-wrapper {
  position: relative;
  margin: auto;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;

  &.absoluted {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

  .small-tms-loader {
    z-index: 9999;
    justify-content: center;
    padding: 15px;
    border-radius: 8px;

    &.active {
      display: flex; }

    .loader {
      text-align: center;
      color: var(--white);
      font-size: 16px; }

    .spinner {
      margin: 0px auto;
      height: 20px;
      text-align: center;
      font-size: 10px;

      > div {
        background-color: $white;
        height: 100%;
        width: 4px;
        display: inline-block;
        animation: pulse-animation 1.2s infinite ease-in-out; }

      .rect2 {
        animation-delay: -1.1s; }

      .rect3 {
        animation-delay: -1.0s; }

      .rect4 {
        animation-delay: -0.9s; }

      .rect5 {
        animation-delay: -0.8s; } } } }
