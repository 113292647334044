.status-dropdown {

  &__icon {
    font-size: 2.5rem;
    margin-left: 5rem; }

  &__container {
    display: none;
    position: absolute;
    transform: translateX(calc(-100% + 7rem));
    z-index: 20;
    min-width: 15rem;
    border-radius: $border-radius;
    box-shadow: 0 0 10px #000000;
    background-color: $color-dark-blue;
    text-align: left;

    &--active {
      display: block; } }

  &__list {
    padding: 0;
    margin: 0;
    font-size: 1.4rem; }

  &__list-item {
    list-style: none;

    &:last-of-type {

      .status-dropdown__link {
        border-bottom: 0; } } }

  &__link {
    display: block;
    text-decoration: none;
    padding: 1rem .5rem;
    border-bottom: 1px solid $color-border;

    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: {} } } }
