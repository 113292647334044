
.popup-card {
  font-size: 13px !important;
  color: var(--white);
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-align: left;
  box-shadow: $card-shadow;
  z-index: 200;
  min-width: 400px;
  display: none;
  border-radius: 4px;
  background: $card-bg;

  &.relative {
    position: relative; }


  &.absolute {
    position: absolute;
    top: calc(100% + 30px);
    right: -10px; }


  &.active {
    display: block; } }

.popup-card__hidden-border {
  position: relative;

  &:after, &:before {
    bottom: 100%;
    right: 0px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; }


  &:after {
    border-color: transparent transparent $card-single-bg transparent;
    border-width: 10px;
    margin-left: -10px; }


  &:before {
    border-color: transparent transparent $card-single-bg transparent;
    border-width: 12px;
    margin-left: -12px; } }



.popup-card__header {
  padding: 10px 20px;
  box-shadow: $shadow-bottom-headers;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .popup-card__header-option {
    cursor: pointer;

    span {
      font-size: 11px !important;

      i {
        font-size: 13px !important; } }

    &:hover {
      > * {
        color: $item-lighter-blue; } } } }

.popup-card__body {
  .popup-card__body-sperator-block {
    border-bottom: $line-divider;
    margin-bottom: 10px;
    padding-bottom: 10px; } }

.popup-card__footer {}

