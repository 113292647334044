.custom-control-label {
  &::before {
    top: 0.3rem;
    left: -1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: transparent;
    border-color: $color-border; }

  &::after {
    top: 0;
    left: -1.7rem;
    width: 2rem;
    height: 2rem; } }
