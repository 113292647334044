html {
  font-size: 62.5%; }

body {
  font-family: "Roboto", sans-serif;
  color: $color-white;
  font-size: 1.4rem; }

p {
  font-size: 1.4rem;
  line-height: 1.8rem; }

small {
  font-size: 1.2rem;
  line-height: 1.3rem; }

// headings

h1 {
  font-size: 3.8rem;
  line-height: 2.4rem;
  font-weight: 300; }

h2 {
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.2rem; }

h3 {
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.1rem; }

.table {
  color: $color-white; }

p {
  font-size: 1.4rem; }

a {
  color: $color-white;
  text-decoration: none; }
