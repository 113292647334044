.card {
  background: $color-dark-blue-200 0% 0% no-repeat padding-box;
  border-radius: 0;
  margin-bottom: 2rem;
  padding: 0;
  min-height: 25rem;

  &__title {
    position: relative;
    font-size: 1.4rem;
    border-bottom: .1rem solid $color-border;
    text-transform: uppercase; }

  &__change-indicatior {
    position: absolute;
    top: 1.7rem;
    right: 1rem;
    font-size: 1.4rem;
    z-index: 2; }

  &__value {
    font-size: 3.8rem;
    font-weight: 300; }

  &__arrow {
    margin-left: .3rem; }

  &__arrow--green {
    color: #22B69E; }

  &__arrow--red {
    color: #F2323F;
    transform: rotate(180deg); }

  &__graph {
    height: 10rem; } }


