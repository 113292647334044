.notifications {
  position: relative;



  .notification-symbol {
    position: absolute;
    right: -4px;
    bottom: 8px;
    color: var(--white);
    background: $cyan;
    border-radius: 100%;
    width: 10px;
    height: 10px;
    text-align: center;
    display: none;

    &__active {
      display: block!important;
      background: $orange!important; } } }




.notifications-list {
  ul {
    cursor: default;
    overflow-y: auto;
    max-height: 400px;
    padding: 0;

    li {
      padding: 20px 10px;
      text-align: left;
      cursor: pointer;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-bottom: $line-divider;

      &.new {
        border-left: 3px solid $cyan;
        box-sizing: border-box; }

      &.read {
        background-color: $main-bg-lighter; }

      .right {
        margin-left: 10px;

        .header {
          margin-bottom: 5px;
          display: flex;
          justify-content: space-between;

          span {
            color: #FFFFFF;
            opacity: 0.5;
            font-size: 11px !important;
            width: 50%;

            &:first-of-type {
              display: flex;
              justify-content: flex-start !important; }

            &:last-of-type {
              display: flex;
              justify-content: flex-end; } } }

        .content {
          width: 300px;
          word-break: break-all;
          margin-bottom: 10px; }


        .footer {
          position: relative;

          .timestamp {
            font-size: 11px !important;
            font-weight: bold;
            color: $item-lighter-blue;
            display: flex;
            align-items: baseline; }

          .action-notification {
            position: absolute;
            top: 0;
            right: 0;

            .action-icon {

              &:hover {
                color: $item-lighter-blue; } } } } }

      i {
        margin-right: 0;
        font-size: inherit !important; }

      &:hover {
        background: $main-bg; } }

    p {
      padding: 20px;
      margin-bottom: 0;
      text-align: center; } } }
.unread-notification {
  background-color: $main-bg; }

.notification-dot {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #D35B1E;
  left: 8px;
  top: -5px;
  border-radius: 50%;
  display: none;
  font-size: 10px;
  text-align: center; }

.bell-shake {
  animation: bell-shake 1s; }

@keyframes bell-shake {
  0% {
    transform: rotate(0); }
  1% {
    transform: rotate(30deg); }
  3% {
    transform: rotate(-28deg); }
  5% {
    transform: rotate(34deg); }
  7% {
    transform: rotate(-32deg); }
  9% {
    transform: rotate(30deg); }
  11% {
    transform: rotate(-28deg); }
  13% {
    transform: rotate(26deg); }
  15% {
    transform: rotate(-24deg); }
  17% {
    transform: rotate(22deg); }
  19% {
    transform: rotate(-20deg); }
  21% {
    transform: rotate(18deg); }
  23% {
    transform: rotate(-16deg); }
  25% {
    transform: rotate(14deg); }
  27% {
    transform: rotate(-12deg); }
  29% {
    transform: rotate(10deg); }
  31% {
    transform: rotate(-8deg); }
  33% {
    transform: rotate(6deg); }
  35% {
    transform: rotate(-4deg); }
  37% {
    transform: rotate(2deg); }
  39% {
    transform: rotate(-1deg); }
  41% {
    transform: rotate(1deg); }
  43% {
    transform: rotate(0); }
  100% {
    transform: rotate(0); } }

