.global-search-bar {
  width: 100%;
  margin-top: 1rem;

  .tail-select {
    min-width: auto;
    .select-label {
      padding: 10px;
      font-size: 1.4rem !important;

      &:after {
        right: 10px;
        top: 10px; } } }

  .input-base {
    .input-group-prepend {
      background-color: initial;
      align-items: center; }
    .clear-input {
      margin-left: -40px;
      color: #cfcfcf;
      display: none; } } }

.search-modal {
  background-color: $color-dark-blue-200 !important;
  padding-right: 0;
  top: 7rem;
  overflow: auto !important; }

.search-filter-icon {
  font-size: 2.5rem;

  &:hover {
    cursor: pointer;
    color: $cyan; }

  &.active {
    color: $cyan; } }

.search {
  width: 100%;

  &__header {
    width: 100%;
    padding: 1rem 0;
    background-color: #1B2238;
    z-index: 2;
    position: relative; }

  &__input {
    width: 100%;
    border-bottom: 0;
    font-size: 2rem;
    padding: 0;
    background-color: #1B2238;
    margin: 0;

    &:focus {
      background-color: #1B2238; }

    &--item {
      margin-left: 1rem;
      position: relative;
      margin-right: 2rem;

      &:last-of-type {
        margin-right: 0rem; }

      &:first-of-type {
        margin-left: 0rem; }

      &:before {
        content: " ";
        border-radius: 5rem;
        height: 1.2rem;
        width: 1.2rem;
        position: absolute;
        left: -1.75rem;
        top: .5rem; } } }

  // results
  &__results-list {
    opacity: 0;
    transition: opacity .3s ease-in-out;
    padding-bottom: 75px;

    &.active {
      opacity: 1;
      transition: opacity .2s ease-in-out; } }

  &__results {
    margin-top: 2rem; }

  &__result {
    margin-bottom: 2rem;
    position: relative;

    &--inner {
      cursor: pointer;
      background: $color-light-blue-500;
      padding: 1rem 2rem 1rem 2rem;

      &:hover {
        background: #323e63; } }

    .title-container {
      font-size: 1.75rem;
      font-weight: 400; }

    .status-container {
      font-size: 1.2rem;

      .status-indicator {
        font-size: 1.1rem; } }

    .ellipsis-overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

    i {
      font-size: 13px; // consistent sizing of all icons for improved text alignment
      color: #b8b8b8; } }

  &__result {
    &--type {
      position: relative;
      top: 0rem;
      left: 0rem;
      width: 100%;
      padding: 0.5rem 0rem 0.5rem 3rem;
      background: $color-dark-blue-100;
      text-indent: 0.5rem;
      border-top: 1px solid $lighter-blue; } }

  &__search-icon {
    margin-right: 2rem;
    font-size: 2rem; }

  &__close-icon {
    font-size: 2rem;
    margin-left: 1.5rem; } }
