.icon {
  &__color {
    &--red {
      color: $item-red; }

    &--blue {
      color: $item-ocean-blue; }

    &--aqua {
      color: $item-aqua; }

    &--green {
      color: $item-green; }

    &--yellow {
      color: $item-yellow; }

    &--orange {
      color: $item-orange; } } }

