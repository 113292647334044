.map-container {
  position: relative;
  max-height: 50rem;
  min-height: 50rem;
  background-color: #8d8d8d;

  .map-reset-zoom {
    position: absolute;
    top: 20px;
    left: 30px; } }

.marker {
  max-width: 25rem;
  border: 0px;
  text-align: center;
  white-space: nowrap;
  position: relative !important;
  overflow: unset !important;
  opacity: unset !important;

  &__content {
    padding: 1.5rem 0.5rem;
    position: relative;
    color: #ffffff;
    background-color: #000000;
    border-radius: 0.4rem;

    &::after,
    &::before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }

    &::after {
      border-color: rgba(0, 0, 0, 0);
      border-top-color: #000000;
      border-width: 1rem;
      margin-left: -3rem; } }

  &--icon {
    position: absolute;
    top: 0rem;
    left: 0rem;
    height: 100%;
    font-size: 1.4rem;
    background: $item-red;
    padding: 1rem;
    border-top-left-radius: 0.4rem;
    border-bottom-left-radius: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center; } }
