.table {
  padding: 0;
  margin: 0;
  background-color: transparent;
  border-collapse: collapse;
  border: .1rem solid $color-border;

  &.table--align-center {
    tbody {
      td {
        vertical-align: middle; } } }

  &.table-striped {
    tbody {
      tr:nth-of-type(odd) {
        background-color: transparent; }

      tr:nth-of-type(even) {
        border-top: .1rem solid $color-border;
        border-bottom: .1rem solid $color-border;
        background-color: rgba(0,0,0,0.1); } } }

  &.opportunities-table {
    td:last-child {
      width: 30%; } }

  &.inactive {
    color: #9c9c9c; }

  thead {
    padding: 0;
    margin: 0;

    tr {
      border-bottom: .1rem solid $color-border;

      th:first-child {
        padding-left: 1rem; }

      th {
        background-color: #191d2c;
        border-top: 0;
        font-weight: 700;
        padding: .8rem 0;
        white-space: nowrap;
        border: 0; } } }

  tr {
    font-weight: 300;
    // so we can fade deleting rows
    opacity: 1;
    transition: opacity 500ms linear;

    th {
      font-weight: 500; } }

  th,
  td {
    border: 0;
    padding: .8rem 0;

    &:first-child {
      padding-left: 1rem; }

    &:last-child {
      padding-right: 1rem; } }

  button {
    background-color: transparent;
    border: 1px solid $color-border;
    padding: .5rem 2rem;
    border-radius: $border-radius;
    color: #EAEAEA;
    font-size: 1.2rem; } }

.user-table {
  background-color: $color-dark-blue-100;
  padding: 2rem;
  height: 40rem;
  display: flex;
  align-items: normal;
  justify-content: center; }


.table.table--dark {
  background-color: #171B2C;

  td {
    padding: .8rem 1rem; } }

.table-options {
  .dropdown-menu {
    background: #191C2C;
    min-width: 150px;
    top: 5px !important;
    box-shadow: 0px -1px 5px -4px #000000;

    li {
      font-size: 1.2rem;
      padding: 1rem;
      color: $white;

      &:hover {
        cursor: pointer;
        color: $orange; } } } }

table.js-route-rates-table,
table.js-route-rates-table-inactive {

  .haulage-price {
    min-width: 13rem; }

  min-width: 100%;

  tr {

    th {
      padding-right: 2rem; }

    td {
      white-space: nowrap;
      padding-right: 2rem; } } }
