.datetimepicker {
  background-color: #232740;
  border: .1rem solid #374171;
  border-radius: .5rem;
  max-width: 23rem;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: -1.1rem;

  &::before,
  &::after {
    display: none; } }

.daterangepicker {
  background: #232740 !important;
  border-color: #333b68 !important;

  .ranges {
    color: inherit;
    li {
      &.active {
        background-color: #333b68 !important; }

      &:hover {
        background-color: #333b68 !important; } } }

  &:after {
    border-bottom-color: #141414 !important; }

  &:before {
    border-bottom-color: black !important; }

  .drp-calendar {
    &.left {
      border-left-color: #4e4e4e !important; } }

  .drp-buttons {
    border-top-color: #4e4e4e !important; }

  .cancelBtn {
    background: #1a1f34;
    color: white;
    &:hover {
      box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px 1px #3b4a78;
      -moz-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px 1px #3b4a78;
      -webkit-box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.1), 0 0 1px 1px #3b4a78; } }

  .drp-selected {
    color: inherit; }

  .applyBtn {
    background: #3c4b78;
    &:hover {
      box-shadow: 0 1px 1px 1px rgba(59, 74, 120, 0.1), 0 0 1px 1px #3b4a78;
      -moz-box-shadow: 0 1px 1px 1px rgba(59, 74, 120, 0.1), 0 0 1px 1px #3b4a78;
      -webkit-box-shadow: 0 1px 1px 1px rgba(59, 74, 120, 0.1), 0 0 1px 1px #3b4a78; } }

  th {
    select {
      color: #dadada !important;
      background: transparent; }

    span {
      border-color: #dadada !important; } }

  td {
    border-radius: 0 !important;
    border-color: #131313 !important;
    color: white !important;
    background-color: #26314c;

    &.off {
      background: #1a1f34 !important;
      color: transparent !important; }

    &.in-range {

      &:not(.end-date) {

        &:not(.off) {

          &:not(.start-date) {
            background-color: #3c4b78 !important; } } } }

    &:hover {
      background: #3c4b78 !important; }

    &.active {

      &:not(.off) {
        background: #333b68 !important; } } }



  .calendar-table {
    border-radius: 0 !important;
    border-color: #1f1f1f !important;
    padding-right: 0 !important;
    background: #1a1f34 !important;
    color: #dadada; } }

@media (max-width: 730px) {
  .daterangepicker {
    .ranges {
      text-align: center;
      margin-bottom: 10px !important;
      width: 100% !important;

      ul {
        width: 100% !important; } }

    .drp-calendar {
      padding: 5px 10px !important;
      border: none;
      max-width: 100% !important; }

    .drp-selected {
      display: block !important;
      margin-bottom: 15px; }

    .drp-buttons {
      text-align: center !important; } } }
