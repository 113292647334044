@import "../global";
@import "../components/login";


.login-container {
  height: 100vh;
  width: 100vw; }

.btn {
  font-size: 80%;
  border-radius: $border-radius;
  letter-spacing: .1rem;
  padding: 1rem;
  transition: all 0.2s;
  background: $color-dark-blue 0% 0% no-repeat padding-box; }

.form-control {
  background: $color-dark-blue-100;
  font-size: 1.8rem; }

.form-label-group {
  margin-bottom: 1.5rem; }

.card {
  border: 0;
  padding: 8rem 5rem 10rem 5rem;
  background: transparent linear-gradient(180deg, #2B375D 0%, #1E243A 100%) 0% 0% no-repeat padding-box;
  box-shadow: 2px 2px 10px #151515;
  opacity: 1;
  border-radius: 0; }

button {
  border-radius: $border-radius; }

a {
  color: $color-white;

  &:hover {
    color: $color-white; } }

main {
  width: 100%;
  height: 100vh;
  background-color: #151618;
  font-family: var(--main-font); }


.img {
  max-width: 445px !important; }


.img-fluid {
  width: 100%;
  height: auto; }


.bottom-shadow {
  -webkit-box-shadow: 0px 6px 8px 5px var(--panel-shadow-color);
  -moz-box-shadow: 0px 6px 8px 5px var(--panel-shadow-color);
  box-shadow: 0px 0px 8px -2px var(--panel-shadow-color); }


.tms-primary-bg {
  background: var(--panel-header); }


.tms-primary-bg-input {
  box-shadow: none;
  border: 0;
  background: rgba(0, 0, 0, 0.31); }


.tms-primary-bg-input:focus {
  background: rgba(0, 0, 0, 0.31);
  border-color: 0;
  outline: 0;
  box-shadow: none; }


#loginError {
  color: var(--_orange);
  text-align: center; }


input.form-control:focus {
  color: #000000 !important;
  background-color: #cccccc !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 68, 0.25) !important;
  transition: .3s !important; }


#forgotPassword {
  display: block;
  margin-top: 10px; }


#loginSingleCol {
  position: relative;
  top: 15vh; }


#loginFormContainer {
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 200px;

  &::before {
    box-shadow: -9px 0 15px -15px inset;
    content: " ";
    height: 100%;
    left: -15px;
    position: absolute;
    top: 0;
    width: 15px;
    color: rgba(0, 0, 0, 0.80); }


  &::after {
    box-shadow: 9px 0 15px -15px inset;
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    right: -15px;
    width: 15px;
    color: rgba(0, 0, 0, 0.80); }


  .form-group {
    margin-bottom: 2rem !important; }


  .gdpr-link {
    &:hover {
      .link {
        color: var(--_orange) !important; } } } }




.login-card {
  width: 450px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 0;

  .logo-circle {
    border-radius: 100%;
    margin: auto;
    border: 2px solid rgba(89, 94, 111, 0.31);
    padding: 18px 5px;
    width: 75px;
    background: #1F253E 0% 0% no-repeat padding-box;
    box-shadow: 0 0px 15px rgba(0, 0, 0,.29);

    img {
      width: calc(100% - 15px); } }



  .card-content {
    background: var(--_card-bg); }


  .gradientRow {
    background: transparent linear-gradient(270deg,#262e49,#324da0,#262e48) 0 0 no-repeat padding-box;
    min-height: 5px; }


  .form-control {
    font-size: 14px;
    color: $color-white;
    background-color: $main-bg-lighter;
    border: 2px solid #353e6c;
    box-shadow: none;
    min-height: 40px; }


  .custom-file-label {
    font-size: 14px;
    color: $color-white;
    background-color: $main-bg-lighter;
    border: 2px solid #353e6c;
    box-shadow: none;
    min-height: 40px;

    &::after {
      font-size: 14px;
      color: #fff;
      background-color: #2b375d;
      height: 100%; } } }
