.reporting-modals {
  .report-modal-footer {
    padding-bottom: 4rem;
    justify-content: center;
    display: flex;
    -ms-flex-align: center;
    align-items: center;

    button {
      padding: 1.5rem 5rem;

      &:not(:first-child) {
        margin-left: 0.25rem; }

      &:not(:last-child) {
        margin-right: 0.25rem; } } }

  .report-modal-body {
    padding: 4rem;
    padding-bottom: 2rem;
    overflow: unset;
    margin-bottom: 2rem;

    &.scrollable {
      max-height: 250px;
      overflow: auto; }

    input {
      background-color: var(--inputColour);
      border: 1px solid #333b68;
      padding: 2rem 1rem;
      font-size: 1.5rem;
      color: white;

      &:focus {
        background-color: var(--inputColour);
        color: white !important; } }


    .input-area {
      font-size: 1.3rem;
      position: relative;
      background-color: transparent;
      border-radius: 0;
      display: flex;
      align-items: center;
      min-height: 38px;
      border: 1px solid #333b68; }

    .message {
      color: red;
      display: none;
      font-size: 1.3rem;
      margin-top: 0.5rem;

      &.show {
        display: block; } }


    .error {
      border-color: red; }

    ul {
      li {
        &.report-item {
          background: #2f3e6f;
          font-size: 1.5rem;
          cursor: pointer;

          &:not(:last-of-type) {
            margin-bottom: 2rem; } } }

      .form-row {
        align-items: center;
        justify-content: space-between;

        .form-group {
          margin-bottom: 0;
          padding: 1.5rem 2rem;

          &--left {
            flex: auto;

            .icon {
              margin-right: 0.5rem;

              &.icon--green {
                color: #84e3a5; }

              &.icon--blue {
                color: #58cfff; } } }

          &:hover {
            background-color: #294190; }

          &--right {
            text-align: center; } } } } }

  .report-modal-dismiss-cta {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 1.5em;
    cursor: pointer; }

  .modal {
    &__title {
      text-align: center;
      padding-top: 4rem;

      &--icon {
        font-size: 4rem; }

      &--title {
        font-size: 3rem;
        margin-bottom: 1rem; }

      &--sub-title {
        font-size: 1.8rem;
        margin-bottom: 2rem; } } }

  .form-group {
    .custom-control {
      position: relative;
      display: block;
      min-height: 1.5rem;
      padding-left: 1.5rem;
      float: unset;
      margin-right: 0rem;

      .custom-control-input {
        background-color: rgba(0,0,0,0);
        border: 1px solid #333b68;
        padding: 1.5rem 1rem;
        font-size: 1rem;
        color: #fff; }

      .custom-control-label {
        position: relative;
        margin-bottom: 0;
        vertical-align: top;

        &::before {
          left: -3rem; }

        &::after {
          top: -0.1rem;
          width: 2rem;
          height: 2rem;
          left: -3.1rem; } } } } }
