.overview-welcome-text {
  display: block;
  padding: 0rem 1rem !important; }

.homeIcon {
  margin: auto 5px 5px 5px;
  font-size: 35px; }

.homeText {
  font-size: 2.25em;
  font-weight: 700;
  margin: auto 5px 0px 5px; }

.hiText {
  font-size: 2.25em;
  margin: 0 0.4rem; }

.overview-cards {
  height: calc(100% - 7.5rem);
  padding: 10rem 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

.cards-wrapper {
  padding: 1.5rem 0rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.card-container {
  background: $content-gradient-background !important;
  width: 40rem;
  height: 25rem;
  position: relative;
  margin-right: 2rem;
  margin-top: 2rem;
  box-shadow: $card-box-shadow;

  &--last {
    margin-right: 0rem; }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
    animation: unset; }

  &:hover {
    .card-container--border {
      display: none; }

    &.bg-color--gradient-pink-blue {
        &::after {
          background: $border-pink-blue; } }

    &.bg-color--gradient-orange-pink {
      &::after {
        background: $border-orange-pink; } }

    &.bg-color--gradient-cyan-blue {
      &::after {
        background: $border-cyan-blue; } }

    &.bg-color--gradient-blue-purple {
      &::after {
        background: $border-blue-purple; } }

    &.bg-color--gradient-green-yellow {
      &::after {
        background: $border-green-yellow; } }

    &.bg-color--blue {
      &::after {
        background: $border-blue; } }

    &.bg-color--white {
      &::after {
        background: $border-white; } }

    &:after {
      animation: frame-enter 0.45s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite; } }

  &--border {
    height: 0.3rem; }

  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

  &__content {
    text-align: center;

    &--icon {
      font-size: 3rem; }

    &--title {
      font-size: 2rem; } }

  .icon {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent; } }

/* motion */
@keyframes gradient-animation {
  0% {
    background-size: 100% 100%;
    background-position: 15% 0%; }

  50% {
    background-size: 100% 100%;
    background-position: 85% 100%; }

  100% {
    background-size: 100% 100%;
    background-position: 15% 0%; } }

@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%); }

  25% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%); }

  50% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%); }

  75% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%); }

  100% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%); } }



@media (max-width: 992px) {
  .hpCard-container {
      width: 100%;
      float: left; }

  .overview-cards {
      padding: 0px;
      padding-bottom: 35px; } }

@media (min-width: 1200px) {
  .hpCard-container {
    width: 25%;
    float: left; } }

// Card border
.bg-color {
  &--gradient-pink-blue {
    background: $border-pink-blue; }

  &--gradient-orange-pink {
    background: $border-orange-pink; }

  &--gradient-cyan-blue {
    background: $border-cyan-blue; }

  &--gradient-blue-purple {
    background: $border-blue-purple; }

  &--gradient-green-yellow {
    background: $border-green-yellow; }

  &--blue {
    background: $border-blue; }

  &--white {
    background: $border-white; } }


// Card Dropdown
.card-dropdown {
  position: absolute;
  right: 3rem;
  top: 3.5rem;
  z-index: 1;
  width: auto !important;

  &.show > .card-dropdown__toggle {
    background-color:  #1E243B!important;

    &:focus {
      box-shadow: none !important; } }

  &__toggle {
    font-size: 2rem !important;
    padding: 0rem;
    min-width: 0 !important;

    &::after {
      display: none; } }

  &__item {
    padding: 1.25rem !important;

    &::before {
      display: none; }

    &:hover {
      background-color: transparent !important;
      color: #ffffff; }

    &--icon {
      margin-right: 0.5rem; } } }
