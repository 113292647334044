.help-block {

  &.error {
    color: $color-red; } }

.form-group {
  &.has-error {
    input,
    select,
    textarea {
      border: .1rem solid $color-red; } }

  &.has-success {
    .form-control {
      border-bottom-color: $color-green; } }

  .select-error {
    border: .1rem solid $color-red !important; } }
