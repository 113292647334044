.error-container {
  // background: red

  &__button {
    background: red;
    margin-top: 4rem;
    padding: 1.5rem 4rem;
    background-color: $color-dark-blue-100;
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #fff;
      background-color: $color-dark-blue-200; } }

  &__icon {
    font-size: 10rem;
    margin-bottom: 3rem;
    color: #ec6f30; }

  &__title {
    font-weight: 400;
    font-size: 8rem;
    line-height: 10rem;
    margin-bottom: 2rem; }

  &__text {
    max-width: 60rem;
    font-weight: 300;
    line-height: 2.5rem; }

  &__show_color {
    color: red; } }
