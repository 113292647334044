.icon-tabs {

  a {
    color: #47536D;
    border: 1px solid $color-border;
    padding: 0.6rem 2.2rem;
    font-size: 1.6rem; }

  .btn-secondary {
    border-radius: $border-radius; }

  .btn-secondary:not(:disabled):not(.disabled) {
    color: #B2B5BD;

    &.active,
    &:hover {
      background: {
        color: #121625;
        border-color: $color-border; }
      border: {
        color: $color-border; } }

    &:focus {
      box-shadow: 0 0 0 0; } }

  a.first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0; }

  a.second {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0; } }
