.slideout-menu {
  min-width: 300px;
  max-width: 350px;
  position: fixed;
  left: 0;
  height: calc(100vh - 7rem);
  transition: width 0.2s ease-in, opacity 1s ease-out;
  transform: translateX(-100%);
  transition: transform 0.2s ease-in-out;
  z-index: 50;
  background: $gradient-background;
  border-right: 1px solid $border;

  &__list-container {
    position: absolute;
    top: 6.5rem;
    width: 100%;
    height: calc(100% - 15rem);
    overflow: auto; }

  &__toggle {
    position: absolute;
    left: 0;
    top: 0;
    height: 7rem;
    width: 7rem;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center; }

  &--active {
    display: block;
    transform: translate(0%);
    height: 100%; }

  &__nav-parent {
    border: 1px solid $border;
    border-left: 0;
    border-right: 0;

    &.active {
      transition: all 0.3s ease-in-out;
      background: $dark-background-color;
      border-left: 0;
      padding-bottom: 2rem;

      .slideout-menu__nav-link {
        border: 0;
        background: transparent; } } }

  .main-menu-footer {
    bottom: 0;
    position: fixed;
    width: 100%;

    &__nav-link {
      &:hover {
        background: transparent; } } }

  &__nav-sub-item {
    display: none;

    &.active {
      display: block;
      transition: all 0.3s ease-in-out; } }

  &__nav-sub-sub-item {
    display: none;

    &.active {
      display: block;
      transition: all 0.3s ease-in-out; } }

  &__nav-link {
    display: flex;
    color: #fff !important;
    padding: 1rem 2rem;
    cursor: pointer;
    align-items: center;

    &.active {
      transition: all 0.3s ease-in-out; }

    &.user-link {
      padding: 10px 20px; }

    &:hover {
      color: #fff;
      transition: all 0.3s ease-in-out;
      background: $dark-background-color;

      .menuIcon {
        opacity: 1; } }

    .user-options {
      position: absolute;
      min-width: 250px;
      left: 20px;
      bottom: 40px;
      display: none;

      .options {
        padding: 20px 30px;
        border-radius: 8px;
        background: $pop-up-gradient-background;

        .option {
          padding: 10px;

          .small-option {
            opacity: 0.3;
            font-size: 12px; }

          &:hover {
            cursor: pointer;
            background: transparent; } } }

      &:after, &:before {
        top: calc(100% - 2px);
        left: 50px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }

      &:after {
        border-color: #2C3858 transparent transparent;
        border-width: 10px;
        margin-left: -10px; }

      &:before {
        border-color: #2C3858 transparent transparent;
        border-width: 12px;
        margin-left: -12px; } }

    &:hover > .user-options {
      display: block; }

    .user-icon {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;

      .right {
        margin-left: .75rem;

        .menu-text {
          line-height: 3rem;
          font-weight: 400;
          padding-left: 1rem; } }

      .side-menu-icon {
        width: 20px;
        text-align: center; } }

    .user-role {
      font-size: .75rem;
      opacity: 0.4; } }

  .disabled {
    color: #9e9e9e !important; }

  .menu-icon {
    width: 20px;
    height: 20px;
    margin: auto; }

  .menu-icon {
    width: 20px;
    height: 20px;
    margin: auto; }

  .navbar-label {
    margin: auto;
    color: white;
    text-align: center; }

  i {
    vertical-align: middle;
    font-size: 2rem; }

  .menu-text {
    font-size: 1.6rem; }

  &__sub-link {
    padding: 1rem 2rem 1rem 4rem;

    i {
      font-size: 1.8rem; }

    .menu-text {
      font-size: 1.4rem; } }

  &__sub-sub-link {
    padding: 1rem 2rem 1rem 6rem;

    i {
      font-size: 1.6rem; }

    .menu-text {
      font-size: 1.3rem; } }

  &__nav-icon {
    position: absolute;
    right: 3rem;
    transition: all 0.3s ease-in-out;

    &.active {
      transform: rotate(90deg);
      transition: all 0.3s ease-in-out; } } }

.color {
  &--gradient-pink-blue {
    background: $border-pink-blue;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

  &--gradient-orange-pink {
    background: $border-orange-pink;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

  &--gradient-cyan-blue {
    background: $border-cyan-blue;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

  &--gradient-blue-purple {
    background: $border-blue-purple;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

  &--gradient-green-yellow {
    background: $border-green-yellow;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

  &--blue {
    background: $border-blue;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

  &--white {
    background: $border-white;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; } }
