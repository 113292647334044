.tablet-icon {

  // variations
  &--activity-feed {
    position: absolute;
    left: -7.7rem;
    top: -1rem; }

  &.tablet-icon--title {

    .tablet-icon__outer {
      width: 4.2rem;
      height: 4.2rem; }

    .tablet-icon__inner {
      width: 3.2rem;
      height: 3.2rem; } }

  &__outer {
    background-color: rgba($color-white, 0.1);
    width: 5.3rem;
    height: 5.3rem;
    content: '';
    border-radius: 50%;
    z-index: -1;
    box-sizing: border-box; }

  &__inner {
    border-radius: 50%;
    width: 4.3rem;
    height: 4.3rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    top: .5rem;
    left: .5rem;
    // border: 6px solid
    box-sizing: border-box;

    &--blue {
      background-color: #333FFF;
      border-color: #2631da; }

    &--purple {
      background-color: #6A44F0; }

    &--green {
      background-color: #23BA99; }

    &--activity-feed {
      position: absolute;
      left: -7.7rem;
      top: -1rem; } } }

a.tablet-icon {
  text-decoration: none;

  &:hover, &:active, &:focus {
    color: white; } }
