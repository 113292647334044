.tag {
  background-color: #3B4A78;
  display: inline-block;
  padding: .2rem 1.5rem;
  border-radius: 2rem;
  margin-right: .5rem;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  cursor: pointer; }

.tag__icon {
  margin-left: 1rem; }
