.btn {
  font-size: 1.4rem;
  border: {
    radius: 0.2rem; }
  padding: 0.5rem 1.5rem;
  border: {
    color: transparent; } // to maintain consistent size with outlined buttons
  transition: all 0.3s ease-in-out;

  &__large {
    padding: 1rem 2rem; } }

.btn-small {
  padding: 0.25rem 0.75rem !important; }

.btn-primary {
  box-shadow: 0px -1px 5px -4px $color-black;
  color: $color-white;
  text-decoration: none;

  &--green {
    background-color: #22b69e !important; } }


.btn-secondary {
  background-color: #1E243B;
  border: .1rem solid $color-border;
  border-radius: $border-radius;

  &:hover {
    border: .1rem solid $color-border;
    background-color: $color-border; } }

.btn[disabled] {
  cursor: not-allowed;
  background: {
    image: none;
    color: #3B4A78; }
  border: {
    width: .1rem;
    color: #3B4A78; }
  color: #ffffff50; }

.btn-link {
  color: $color-orange; }

.btn-green {
  background-color: #22b69e;
  color: #fff;

  &:hover {
    color: #fff; } }

.btn-outline {
  border: .1rem solid $border;
  color: #fff;
  background-color: transparent;

  &:hover,
  &--selected {
    color: #fff;
    background-color: $btn-primary;
    border-color: $btn-primary; } }

.account-status-button {
  padding: 1.2rem 1.5rem;
  border-radius: .5rem;
  cursor: no-drop; }

.back-to-top-button {
  position: fixed;
  bottom: 5rem;
  width: 50px;
  height: 50px;
  right: 2rem;
  z-index: 99999;
  background: rgb(51, 63, 255);
  text-align: center;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 2rem;
  cursor: pointer;

  &:hover {
    background: #262FBB;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75); } }
