.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 7rem;
  z-index: 1080;
  padding: 0 2rem;
  background: $dark-background-color;
  border-bottom: 1px solid $border;

  .notification-icon {
    transition: color .15s ease-in-out;

    &.active {
      color: #32b561; } }

  &.navbar-expand-xl {
    justify-content: space-between;

    .navbar-collapse {
      flex-grow: unset; } }

  .navbar-toggler {
    .navbar-toggler-icon {
      i {
        font-size: 2rem;
        color: $color-white; } } }

  .logo {
    margin-left: 25px;
    &.logo--vendor {
      width: 3rem; } }

  .navbar-nav {

    .nav-item {
      position: relative;
      margin-right: 3rem;
      cursor: pointer;

      &.user-area {
        cursor: default; }

      .dropdown-menu {
        padding: 0;
        top: calc(100% + 1.9rem);
        left: -1rem;
        box-shadow: 1px 5px 8px 0px $color-dark-blue;
        font-size: inherit;
        border-radius: 0;
        border-top: 0;
        border-color: $color-light-blue;
        background-color: $color-dark-blue-100;

        .dropdown-item {
          color: $white;
          padding: 0.5rem 1.5rem;
          text-decoration: none;

          &:hover {
            color: $white;
            background-color: $color-dark-blue;
            text-decoration: none;

            @include media-breakpoint-down(md);
            margin-right: 2rem; } } }

      button {
        i {
          font-size: 1.3rem;
          margin-right: 0.4rem; } }
      i {
        font-size: 2rem;
        vertical-align: middle;

        &.flip {
          transform: scaleX(-1); }

        &.search {
          font-size: 1.5rem;
          background: $color-dark-blue;
          border-radius: 100%;
          padding: 1rem; } }

      .user-icon {
        display: flex;
        align-items: center;

        .right {
          margin-left: 1rem;

          .user-name {
            font-size: 1.5rem; }

          .user-role {
            font-size: 1.2rem;
            opacity: 0.4; } } }
      &.global-search-nav-item {
        width: 675px; } }

    .v-line {
      height: 35px;
      width: .1rem;
      background: $color-border;
      margin-right: 1.5rem; } }

  &__mobile-utilities {
    float: right;

    li {
      list-style: none;
      float: right;
      margin-left: 2rem;
      font-size: 2rem; } } }

.mobile-nav-items {
  background-color: $color-dark-blue-100;
  margin-top: -3rem;

  ul {
    li {
      padding: 1.5rem;
      border-bottom: .1rem solid $color-border; } } }

.filter-spacer {
  margin-top: 70px; }

.filter-tail-select-text {
  font-size: 10rem !important; }

.select-label {
  font-size: 1.7rem !important; }

#js-date-field {
  font-size: 1.6rem !important;
  margin-left: 55px;
  margin-top: 15px;
  cursor: pointer; }

.filter-icon {
  margin-right: 10px;
  cursor: pointer; }

.remove-filter-icon {
  cursor: pointer; }

#job-board-filter {
  margin-left: 2px; }

#job-board-reset {
  background-color: $color-dark-blue !important;
  margin-right: 2px; }

.fake-dropdown {
  position: absolute;
  top: 1.4rem;
  right: 1.4rem;
  font-size: 1.6rem;
  cursor: pointer; }

.fake-icon {
  position: absolute;
  top: 1.2rem;
  left: 3rem;
  font-size: 1.8rem; }

.input-base .tail-select {
  margin-top: 1.56rem;
  margin-bottom: 1rem; }

.ag-center-cols-viewport {
  cursor: pointer; }

#filter-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
  transition-property: all;
  transition-duration: 1s; }

.filter-show {
  display: block !important;
  transition-property: all;
  transition-duration: 1s; }

.page-opaque {
  opacity: 0.1 !important;
  transition-property: all;
  transition-duration: 1s; }

.daterangepicker {
  select.yearselect, select.monthselect {
    background: $item-dark-blue; } }

.active-icon {
  color: cyan; }

