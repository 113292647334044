.datatables {
  padding: 3rem 3rem 4rem 3rem;

  &--sm-padding-bottom {
    padding: 3rem 3rem 1rem 3rem; }

  &--no-padding-right {
    padding-right: 0rem; }

  &--no-padding {
    padding: 0rem; }

  &--col {
    padding: 0rem; }

  &__filters {
    padding: 1rem 0rem;
    display: flex;
    justify-content: flex-start;

    &--left-side {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex: 50%; }

    &--right-side {
      display: flex;
      justify-content: flex-end;
      align-content: center;
      flex: 50%; }

    &--input-group {
      max-width: 25rem;
      border: 1px solid $border;
      border-radius: 0.5rem; }

    &--input {
      background-color: $input-background;
      border: 0;
      margin-bottom: 0;
      max-width: 25rem; }

    &--input-prepend {
      padding: 0 .25rem 0 .25rem;
      background-color: $input-background;
      border-radius: 0.5rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0; }

    &--input-prepend-text {
      color: $input-text-color; }

    &--input-append {
      padding: 0 .25rem 0 .25rem;
      background-color: $input-background; }

    &--input-append-text {
      color: $input-text-color; } } }

.ag-theme-balham-dark {
  height: 55rem;
  background: transparent linear-gradient(180deg, #26314C 0%, #222B43 100%) 0% 0% no-repeat padding-box;

  .ag-root-wrapper-body {
    &.ag-layout-normal {
      height: 55rem;
      min-height: 100%; } }

  .ag-root-wrapper {
    &.ag-layout-auto-height {
      height: 100%; } }

  .grid-table-component {
    height: 100%; }

  &.ag-popup {
    min-height: unset; }

  .ag-center-cols-container,
  .ag-center-cols-clipper {
    min-height: auto!important; }

  .ag-body-viewport {
    max-height: 55rem;
    overflow-y: auto; }

  .ag-header {
    background: $dark-background-color; }

  .ag-column-hover {
    background-color: inherit; }

  .ag-header-row {
    color: #ffffff !important; }

  .ag-row {
    border-top: 1px solid #353F6E;
    background-color: #26314C;
    overflow: visible; }

  .ag-row:nth-child(even) {
    background-color: #232740;

    &:last-child {
      border-bottom: 1px solid #353F6E !important; } }

  .ag-row-hover {
    background-color: #171b29 !important; }

  .ag-root-wrapper-body {
    min-height: 75px; }

  .ag-row-selected {
    background-color: $btn-primary !important; }

  .ag-cell {
    display: flex;
    align-items: center; }

  .ag-cell-focus,.ag-cell-no-focus {
    border-color: inherit;
    border:none !important {} }

  .no-border.ag-cell:focus {
    border:none !important {}
    outline: none; }

  .ag-icon-checkbox-unchecked {
    background-color: #26314C !important;

    &:before {
      color: #4B5998; } }

  .ag-details-row {
    background-color: #3e4d75;
    padding: 0;

    .ag-center-cols-viewport {
      overflow: auto !important; }

    .ag-center-cols-clipper {
      height: auto !important; }

    .ag-body-horizontal-scroll {
      display: none !important; } }

  .ag-root-wrapper {
    .ag-layout-normal {} }
  height: 100%!important; }

.ag-details-grid .ag-root-wrapper {
   min-height: unset !important; }

// Status
.status-group {
  display: flex;
  align-items: center;

  &__icon {
    width: .9rem;
    height: .9rem;
    border-radius: 50%;
    margin-right: 0.5rem;

    &--active {
      background-color: $item-green; }

    &--locked {
      background-color: $item-red; }

    &--expired {
      background-color: $item-light-orange; }

    &--disabled {
      background-color: $item-light-gray; }

    &--not-activated {
      background-color: $item-ocean-blue; }

    &--red {
      background-color: $item-red; }

    &--light-green {
      background-color: $item-green; }

    &--green {
      background-color: $item-green; }

    &--yellow {
      background-color: $item-yellow; }

    &--orange {
      background-color: $item-orange; }

    &--blue {
      background-color: $item-lighter-blue; }

    &--dark-green {
      background-color: $item-dark-green; } } }



.ag-popup {
  .ag-menu {
    margin: 0;
    background: $content-gradient-background;

    .ag-menu-option {
      height: 3.5rem;
      font-size: 1.2rem; }

    .ag-menu-option-active {
      background: $dark-background-color; }

    .ag-menu-option-icon {
      padding-left: 1.5rem; } } }


.ag-pinned-right-cols-container,
.ag-pinned-right-header,
.ag-cell {
  border-left: 0!important; }

.ag-root-wrapper {
  .ag-layout-normal {
    height: unset;
    min-height: unset; } }

.ag-cell-first-right-pinned {
  span {
    margin-left:auto !important {}
    margin-right:auto !important {} } }

.datatables__modal {
  min-width: 650px; }

.ag-pinned-right-header {
  .ag-header-row {
    .ag-header-cell {
      .ag-cell-label-container {
        .ag-header-cell-label {
          span {
            margin-left:auto !important {}
            margin-right:auto !important {} } } } } } }

.datatables__modal_jobs {
  min-width: 900px; }

.grid-table-statement .ag-root {
  width: unset; }

.ag-column-panel {
  max-height: 60rem;
  overflow: auto; }

.ag-column-select-panel {
  min-height: 30rem !important; }

.ag-full-width-row {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $dark-background-color !important; }


// Mini tables
.datatables {
  &__mini-table {
    .ag-root-wrapper-body {
      &.ag-layout-normal {
        height: 100%; } } } }

.ag-grid--half-line {
   line-height: 1.6rem; }
