#activityExpiryBanner {
  z-index: 999999;
  border: 0;
  margin: 0;
  width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: $color-orange;
  color: $white;

  p {
    padding: 0;
    margin: 0; } }

.js-alert-placeholder {
  position: fixed;
  top: 0rem;
  width: 100%;
  z-index: 2000;

  .alert {
    border-radius: 0; } }

.pagination-banner {
  position: fixed;
  height: 20px;
  z-index: 9999;
  background: #1E243B8F;
  width: 100%;
  top: 7rem;
  padding: 20px;
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  border-bottom: 1px solid #2A395A; }
