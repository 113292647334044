.modal {
  padding-top: 8rem;

  .page-wrapper__gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999; }

  .confirmation-message {
    font-size: 1.4rem;
    font-weight: normal; }

  .icon-status {
    font-size: 4rem;

    &.icon-info {
      color: #466EB6; }

    &.icon-danger {
      color: red; }

    &.icon-warning {
      color: yellow; }

    &.icon-success {
      color: $item-green; } }


  .modal-info {
    font-size: 2rem; }

  &__close {
      position: absolute;
      right: 2rem;
      top: 1rem;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: 0;

      &:focus {
        border: 0;
        outline: 0; }

      span {
        font-size: 2rem;
        box-shadow: none;
        text-shadow: none;

        i {
          color: #fff; } } }

  h5 {
    font-size: 1.6rem; }

  form {
    width: 100%; }

  @include media-breakpoint-down(md) {
    padding-right: 0!important; } // important due to style being generated by javascript

  .modal-dialog {
    max-width: unset;
    width: unset;
    // margin: 0 auto
    // border-top: 3px solid #5E77BC38
    // background: rgb(255,0,0)
    // background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,128,255,1) 100%)

    &--small {
      width: 44rem;
      max-width: 100%; }

    &--small-ext {
      width: 55rem;
      max-width: 100%; }

    &--medium {
      width: 80rem;
      max-width: 100%; }

    &--medium-ext {
      width: 100rem; }

    &--large {
      width: 75vw; }

    &--extra-large {
      width: 85vw;

      @include media-breakpoint-down(md) {
        width: 100vw; } }

    &--overflow-auto {
      overflow-x: visible;
      overflow-y: auto !important; }

    .modal-content {
      background: $color-dark-blue-200;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
      border-radius: 0;
      padding: 3rem;
      margin: 0 auto;

      &.model-content-unset-width {
        width: unset; }

      .modal-header {
        position: relative;
        border-bottom: 0;
        font-size: 1.6rem;
        align-items: center;
        justify-content: center; }

      .modal-footer {
        border: 0;
        flex-wrap: none;

        .footer-wrapper {
          padding: 1rem 1.5rem 1rem 1.5rem;
          justify-content: flex-end;
          flex-direction: row;
          flex: 1;
          display: flex; }

        .btn {
          margin-left: 0rem;
          padding: 0.7rem 6rem;
          white-space: nowrap; }

        .btn + .btn {
          margin-left: 1rem; }

        .btn-secondary:hover {
          background-color: $btn-secondary-hover; }

        &.modal-footer--new-lead {
          padding-left: 0;
          padding-right: 0; } }

      &--overflow-auto {
        overflow-x: visible;
        overflow-y: auto !important; }

      .modal-body {
        @include media-breakpoint-down(md);
        max-height: 80vh;
        overflow-y: auto;
        overflow-x: hidden;

        .inner-body {
          padding: 2rem 0rem; }

        &--overflow-visible {
          overflow-x: visible;
          overflow-y: visible !important; }

        &--overflow-auto {
          overflow-x: visible;
          overflow-y: auto !important; } } } } }

.modal__title {
  font-size: 3.5rem;
  text-align: left;
  margin-bottom: 1rem;

  &-icon {
    font-size: 2rem;
    display: block; } }

.modal__sub-title {
  padding-bottom: 1rem; }

// bespoke height for new lead modal
#new-lead-modal {
  .modal-dialog {
    .modal-content {
      .modal-body {
        max-height: 70vh; } } } }

// bespoke height for add contact modal
#add-contact, #add-new-user, #edit-user {
  .modal-dialog {
    .modal-content {
      .modal-body {
        overflow-y: visible !important;
        overflow-x: visible !important; } } } }

//z-index for confirmation modal
#confirmation-modal {
  z-index: 1150 !important; }

//z-index for view pdf
#view-pdf {
  z-index: 1155 !important;

  .modal-lg {
    max-width: 95vw;
    .modal-content {
      height: 85vh; }

    .js-pdf-view, .pdf-viewer {
      height: 100% !important;
      width: 100%; }
    .js-pdf-fields {
      padding: 1rem 3rem 1rem 1rem;
      background-color: rgba(0,0,0,.5);
      border: 1px solid #374171;
      white-space: nowrap; } } }



.padded {
   width: 80%;
   margin: 0 auto; }

.padded--small {
   width: 60%;
   margin: 0 auto; }


.user-tags {
  height: 75px;
  margin-bottom: 1rem;
  border-radius: 0.4rem;
  background: $input-background;
  border: 0.1rem solid $border;
  overflow: auto;
  padding: 1rem;

  &__tag {
    display: inline-block;
    background: $primary-btn;
    padding: 0.5rem;
    border-radius: 0.2rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;

    &--remove {
      margin: 0rem 0.5rem;
      vertical-align: middle;

      &:hover {
        cursor: pointer;
        color: $item-light-gray; } } } }


// load / save templates modals

.templates {
  background-color: $main-bg-lighter;
  border: .1rem solid $border;
  border-radius: .5rem;
  height: 20rem;
  max-height: 40rem;
  overflow-y: scroll;

  &__item {
    width: 100%;
    border-bottom: .2rem solid #171B33;
    padding: 1.5rem 0 1.5rem 1.5rem;
    transition: all 0.2s ease-in-out;

    &:last-of-type {
      border-bottom: 0; }

    &:hover {
      cursor: pointer;
      background-color: #171B33;
      transition: all 0.2s ease-in-out; } } }

.modal__remove-overflow {
  overflow-x: unset!important;
  overflow-y: unset!important; }


.js-copy-job-modal {
  .reduced-width-container {
    width: 68%; } }

.create-invoice {
  .content-wrapper {
    height: 200px; } }
