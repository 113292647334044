.bg-image-carousel {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.2;

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-size: cover !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;

    &.slide {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 3s linear;

      &:first-child {
        opacity: 1; } }

    // &:nth-child(1n + 1)
    //   background: url('../../../assets/images/backgrounds/stock-4.jpg')

    // &:nth-child(1n + 2)
 } }    //   background: url('../../../assets/images/backgrounds/stock-2.jpg')


