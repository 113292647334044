.glider-dots {
  padding: 0.5rem 0rem;

  .glider-dot {
    background: $item-blue;

    &.active {
      background: $item-lighter-blue; } } }

.glider {
  flex-direction: row;
  flex-wrap: nowrap; }


