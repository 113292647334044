.dropzone {
  background: $main-bg-lighter;
  border: 1px dashed $border;

  &.error {
    border: 1px solid $item-red; }

  .dz-icon {
    margin-bottom: 2rem; } }

.dz-template-previews {
  padding: 1rem;
  max-height: 15rem;
  overflow: auto;

  .dz-preview-box {
    display: flex;
    align-items: center;
    padding: 1rem 0rem;

    .icon {
      margin-right: 1rem; }

    .progress-details {
      height: 4rem;
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;

      .dz-filename {
        font-size: 1rem;
        position: absolute;
        top: 0rem;
        display: flex; }

      .dz-progress {
        height: 0.5rem;
        width: 95%;
        position: relative;
        background: $main-bg-lighter;

        .dz-upload {
          background: $item-lighter-blue;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 0;
          transition: width 300ms ease-in-out;
          height: 100%; } }

      .dz-size {
        font-size: 1rem;
        position: absolute;
        bottom: 0; }

      .dz-progress-percentage {
        right: 5%;
        font-size: 1rem;
        position: absolute;
        bottom: 0; } }

    &.dz-error {
      .dz-error-mark {
        display: block;
        margin-left: 1rem;
        color: $color-red; } }

    &:not(.dz-error) {
      .dz-error-mark {
        display: none; } }

    &:not(.dz-success) {
      .dz-success-mark {
        display: none; } } } }
