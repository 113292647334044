/* Slider (Toggles) */
label.switch {
  position: relative;
  display: block;
  float: left;
  width: 25px;
  height: 15px;
  vertical-align: text-top;
  margin-left: 5px;

  &:not(.form-check-label):not(.custom-control-label) {
    margin-top: .5rem; } }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $item-lighter-blue;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 3px;
  bottom: 3.5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

input:checked + .slider {
  background-color: $cyan; }

input:focus + .slider {
  box-shadow: 0 0 1px red; }

input:checked + .slider:before {
  -webkit-transform: translateX(8px);
  -ms-transform: translateX(8px);
  transform: translateX(8px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }
