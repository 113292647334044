html {
  .page-loader {
    overflow: hidden; } }

#spinner {
  background: $backdrop-opacity;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: flex;
  top: 0;

  .content {
    background: rgba(0, 0, 0, 0.85);
    padding: 2.5rem 5rem;
    border-radius: $border-radius; }

  &.active {
    display: flex; }

  .loader {
    text-align: center;
    color: var(--white); }

  .spinner {
    height: 3.5rem;
    text-align: center;
    font-size: 1rem;

    > div {
      background-color: $white;
      height: 100%;
      width: 0.4rem;
      display: inline-block;
      -webkit-animation: pulse-animation 1.2s infinite ease-in-out;
      animation: pulse-animation 1.2s infinite ease-in-out; }

    .rect2 {
      animation-delay: -1.1s; }

    .rect3 {
      animation-delay: -1.0s; }

    .rect4 {
      animation-delay: -0.9s; }

    .rect5 {
      animation-delay: -0.8s; } } }

