.select2-container {
  padding: 0;
  background-color: transparent;
  border-bottom: none; }

.select2-container--default {
	.select2-selection--multiple {
		padding: 0;
		background-color: transparent;
		border: none;
		border-radius: 0;
		cursor: text; } }

.select2-container--default {
	&.select2-container--focus {
		.select2-selection--multiple {
			padding: 0;
			background-color: transparent;
			border: none;
			border-radius: 0; } } }

.select2-container--default {
	.select2-selection--multiple {
		.select2-selection__choice {
			background-color: #3B4A78;
			display: inline-block;
			padding: .2rem 1.5rem;
			border-radius: 2rem;
			margin-right: .5rem;
			font-size: 1.4rem {
      margin: 0 0 1rem; }
			cursor: pointer;
			border: none;
			max-width: 130px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden; } } }

.select2-container--default {
	.select2-selection--multiple {
		.select2-selection__choice__remove {
			font-size: 2.2rem;
			line-height: 1.1rem;
			color: #ddd;
			vertical-align: middle;
			margin-right: 1rem; } } }

.select2-container--default {
	.select2-selection--multiple {
		.select2-selection__choice__remove {
			&:hover {
				color: #fff; } } } }

.select2-container--default {
	.select2-selection--multiple {
		.select2-selection__rendered {
			padding: .75rem; } } }

.select2-dropdown {
  background-color: $dark-background-color;
  display: inline-block;
  border-radius: 0;
  font-size: 1.4rem;
  cursor: pointer;
  margin: 0; }

.select2-container--default {
	.select2-results__option--highlighted[aria-selected] {
		background-color: #1E243B;
		color: white; } }

.select2-container--default {
	.select2-results__option[aria-selected=true] {
		background-color: #1E243B; } }

.select2-container--default {
	.select2-search--inline {
		.select2-search__field {
			color: #ddd; } }

 }	// clear icons

.select2-container {
	.select2-selection--single {
		.select2-selection__clear {
			position: absolute;
			top: .6rem;
			right: 2.8rem; } }

	.select2-selection--multiple {
		.select2-selection__clear {
			position: absolute;
			top: .5rem;
			right: 1.5rem; } } }
