.ui-timepicker-standard {
  border: 0 !important;
  background: #232740 !important;
  color: #ffffff;
  font-family: inherit !important;
  font-size: 1.4rem !important;
  z-index: 9999 !important;
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
  margin-top: 0.6rem;
  margin-left: -.8rem;

  a {
    color: #ffffff !important; } }

.ui-timepicker {
  padding: 0 !important;

  .ui-timepicker-viewport {
    padding-right: 0 !important;
    width: 100% !important; }

  .ui-menu-item {
    width: 100% !important;

    a {
      width: 100% !important; } }

  .ui-state-hover {
    transition: all 0.2s ease-in-ease-out;
    background-color: #3B4A78 !important;
    border-color: transparent;
    cursor: pointer; } }

.time-input {
  max-width: 6rem; }

.time-input-fill {
  max-width: unset; }
