.login {
  position: relative;
  background-color: $color-dark-blue-200;
  background-image: linear-gradient(45deg,#26314C,#262D47) 0 0 no-repeat padding-box;
  text-align: center;
  border-radius: 0;
  padding: 48px;
  box-shadow: 0 0 2rem #191919;
  width: 90%;
  min-width: 450px;
  max-width: 43rem;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    width: 100%;
    margin: 0;
    padding: 0;
    background: transparent linear-gradient(270deg,#262e49,#324da0,#262e48) 0 0 no-repeat padding-box; }

  &__logo {
    display: block; }

  &__title {
    margin-bottom: 3rem;
    font-size: 1.8rem; }

  &__submit {
    font-size: 1.6rem !important;
    font-weight: 400;
    display: block;
    width: 100%;
    padding: 0 0 !important;
    box-shadow: 0 .8rem 1.1rem -1rem #040404;
    border-radius: $border-radius;
    margin-bottom: 2rem; } }


//autofill overwrite
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px $color-dark-blue-100 inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  font-weight: 300 !important;
  caret-color: #ffffff !important;
  font-size: 1.8rem !important;
  height: 4rem !important;
  border-radius: 0.5rem !important;
  border: 0 !important; }

.login-page {
  background: {
    cover: #191C2C;
    image: url('../../assets/images/login-bg.png');
    size: cover; }
  overflow: hidden;

  h3 {
    font-weight: 600; } }

.login__submit {
  background-color: $btn-primary !important;
  height: 4rem;

  &:hover {
      border-color: $btn-primary !important; } }
