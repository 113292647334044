.here {
  &__map {
    &--wrapper {
      height: 100%;
      width: 100%; }

    &--container {
      height: 100%;
      width: 100%; }

    &--title {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      z-index: 2;
      padding: 2rem;
      font-size: 1rem;
      font-weight: bold;
      max-width: 15rem; } }

  &__marker {
    &--wrapper {
      background: rgba(102,255,153, 0.8);
      display: flex;
      border-radius: 3.5rem;
      height: 3.5rem;
      width: 3.5rem;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 0 0.5rem rgba(102,255,153, 0.4); }

    &--tooltip {
      .H_ib_body {
        padding: 0.5rem !important;
        background: rgba(102,255,153, 0.4);
        border: 2px solid rgba(102,255,153, 0.4); }

      .H_ib_content {
        width: 15rem;
        text-align: center;

        &:after {
          content: ' ';
          width: 0;
          height: 0;
          position: absolute;
          border-left: 0.75rem solid transparent;
          border-right: 0.75rem solid transparent;
          border-top: 0.5rem solid rgba(102,255,153, 0.4);
          top: 100%;
          right: 0; } } }

    &--tooltip-label {
      font-size: 1.6rem;
      color: white;
      text-align: center; }

    &--inner {
      color: #27964c;
      font-size: 1.4rem; } } }

.H_ui {
  z-index: 5; }
