.filter-checkboxes {
  overflow: hidden; }

.filter-checkbox {

  &__square {
    display: relative;
    display: inline-block; }

  &__square:before {
    content: '';
    width: .8rem;
    height: .8rem;
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 1rem;
    margin-left: .8rem; }

  &__square.green:before {
    background-color: $color-green; }

  &__square.purple:before {
    background-color: $color-purple; } }
