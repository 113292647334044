// Default colours
$color-white: #ffffff;
$color-black: #000000;

$color-border: #2A395A;

// White theme colours
$color-white-opacity-20: rgba($color-white,.2);
$color-white-opacity-08: rgba($color-white,.08);
$color-white-opacity-80: rgba($color-white,.8);

// Black theme colours
$color-black-80: #00000080;

// Grey theme colours
$color-grey: #282828;
$color-grey-100: #252525;
$color-grey-200: #141414;
$color-grey-300: #050505;

// Orange theme colours
$color-orange: #F76F34;
$color-orange-100: #D35B1E;

// Orange theme colours
$color-green: #23BA99;

// Purple theme colours
$color-purple: #374FC7;

// mainly used for error messages
$color-red: #dc3545;

// Light Blue theme colours
$color-light-blue: #A9BBE2;
$color-light-blue-300: #3D4459;
$color-light-blue-500: #2e3858;

// Dark Blue theme colours
$color-dark-blue: #191C2C;
$color-dark-blue-100: #1E243B;
$color-dark-blue-200: #242D4A;
$color-dark-blue-800: #A5A5A5;

// Back Drop Opacity
$backdrop-opacity: #00000040;

$border-radius: .5rem;

// breakpoints:
$screen-sm-min: 768px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);

// @media (max-width: $screen-xs-max) { ... }
// @media (min-width: $screen-sm-min) { ... }
// @media (max-width: $screen-sm-max) { ... }
// @media (min-width: $screen-md-min) { ... }
// @media (max-width: $screen-md-max) { ... }
// @media (min-width: $screen-lg-min) { ... }

// Colours from Speedy Freight / AES for notifications etc
$cyan: #48C2A1;
$red: #F2323F;
$orange: #E55616;
$lighter-blue: #5E77BC;
$yellow: #f1de3d;

$main-bg: #161D32;
$main-bg-lighter: #232740;
$card-single-bg: #26314C;
$card-bg: transparent linear-gradient(45deg,#26314C,#262D47) 0 0 no-repeat padding-box;
$card-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);

$shadow-bottom: 0px 1px 2px rgba(0, 0, 0, 0.2);
$shadow-bottom-headers: 0px 3px 4px rgba(0, 0, 0, 0.2);
$shadow-right: 3px 0 2px -2px rgba(0, 0, 0, 0.2);

$link-color: $card-bg;
$link-hover-color: #1c2338;

$line-divider: 1px solid rgba(0, 0, 0, 0.2);
$card-line-divider: rgba(49,58,86, 0.8);

$table-headers: rgba(18, 22, 37, 0.2) 0% 0% no-repeat padding-box;

$input-bg: #1C243E;
$input-border: 1px solid #374171;

$btn-primary: #3B4A78;
$btn-primary-hover: #4C5F9B;
$btn-primary-disabled: rgba(59, 74, 120, 0.29);

$active-toggle-color: $cyan;
$disabled-toggle-color: $btn-primary;

$btn-secondary: #2C3858;
$btn-secondary-pressed: #171929;
$btn-secondary-hover: #0b0c14;
$btn-secondary-disabled: rgba(35, 39, 64, 0.39);
$btn-secondary-border: 1px solid #374171;

// Application colours
$item-orange: #E55616;
$item-light-orange: #EC6F30;
$item-red: #F2323F;
$item-light-red: #FE4561;
$item-green: #22B69E;
$item-aqua: #35a6bb;
$item-gray: #707070;
$item-light-gray: #CBC9C9;
$item-lighter-blue: #5E77BC;
$item-light-blue: #2C3858;
$item-blue: #3B4A78;
$item-dark-blue: #26314C;
$item-ocean-blue: #327ff2;
$item-blue-tag: #2258b6;
$item-yellow: #dddd00;
$item-dark-green: #339933;

$faint-background-color: rgba(0, 0, 0, 0.15);
$faint-blue-background-color: #2C3556;
$dark-background-color: #1A1F33;
$gradient-background: transparent linear-gradient(180deg, #26314C 0%, #1A1F33 100%) 0% 0% no-repeat padding-box;
$content-gradient-background: transparent linear-gradient(180deg, #26314C 0%, #222B43 100%) 0% 0% no-repeat padding-box;
$dropdown-background: #232740;
$pop-up-gradient-background: transparent linear-gradient(180deg, #3B4A78 0%, #2C3858 100%) 0% 0% no-repeat padding-box;

$border: #374171;
$border-pink-blue: linear-gradient(to left, #5AA5FA, #D93466);
$border-orange-pink: linear-gradient(to left, #5AA5FA, #D93466);
$border-cyan-blue: linear-gradient(to left, #114F81, #01E2F0);
$border-blue-purple: linear-gradient(to left, #2F24E4, #C437D2);
$border-green-yellow: linear-gradient(to bottom, #54EAAC, #B4E10D);
$border-blue: linear-gradient(to left, #5e77bc, #5e77bc);
$border-white: linear-gradient(to left, #ffffff, #ffffff);

$primary-btn: #3B4A78;
$primray-btn-green: #22B69E;
$secondary-btn: #2C3858;

$scroll-bar: #5E77BC;
$scroll-bar-background: #232740;

$input-background: #232740;
$input-text-color: #374171;

$card-box-shadow: 0rem 0.3rem 0.6rem #00000029;

$label-disabled: #6c757d;
